import React from "react";

export default function AboutMe() {
  return (
    <div class="descriptions row last">
      <h1 style={{ fontWeight: "bold" }}>About Me<br/></h1>
      <span>
        As a Senior Software Developer at @Ceridian, I work with cutting-edge
        technologies such as React, React Native, Angular, MongoDB, and Node.js
        to create web applications that enhance human capital management
        solutions for our clients.
        <br />
        I am responsible for designing, developing, testing, and deploying
        features and functionalities that meet the business requirements and
        user expectations.
        <br />
        I have around six years of experience in full-stack development, having
        worked with various Javascript frameworks and databases in different
        domains and projects. I have contributed to the success of Appiness
        Interactive, where I was an Ace Full Stack Developer, and Acepro
        Consulting, where I started as an Android Developer Intern and
        progressed to a Software Developer.
        <br />
      </span>
    </div>
  );
}
