import React from 'react'
import logo from '../images/fontbolt.png';

export default function HeaderComponent() {
  return (
    <nav>
      <div class="mock"></div>
      <div class="fixed">
        <div class="nav-content">
          <img class="logo" alt="logo" src={logo} />
          <div class="desktop-only">
            <div class="search-guide">
              <span class="search-icon"></span>
              <span class="search-placeholder">Search</span>
            </div>
          </div>
          <div>
            <button class="primary">Contact</button>
          </div>
        </div>
      </div>
    </nav>
    
  )
}
