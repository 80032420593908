import React, { Component } from "react";
import HeaderComponent from "./components/HeaderComponent";
import BasicProfile from "./components/BasicProfile";
import AboutMe from "./components/AboutMe";
import Journey from "./components/Journey";
import Projects from "./components/Projects";
import "./styles.css";
export default class App extends Component {
  render() {
    return (
      <body>
        <HeaderComponent />
        <main>
          <BasicProfile />
          <AboutMe />
          {/* <Journey /> */}
          {/* <Projects /> */}
        </main>
      </body>
    );
  }
}
