import React from "react";
import profileLogo from '../images/profile-logo.png';

export default function BasicProfile() {
  return (
    <header>
      <div class="header-wrap">
        <div class="profile-pic">
          <img src={profileLogo} alt="profile-logo" />
        </div>
        <div class="profile-info">
          <div class="title row">
            <h2>codes_by_rj</h2>
            <span class="verfied-icon"></span>
            <button class="primary">Follow</button>
          </div>
          <div class="desktop-only">
            <div class="details row">
              <ul>
                <li>
                  <span>5.6M</span> Experiance
                </li>
                <li>
                  <span>20+</span> Projects
                </li>
                <li>
                  <span>2018</span> Graduated
                </li>
              </ul>
            </div>
            <div class="descriptions row last">
              <h1>Ravi Kiran J</h1>
              <span>
                Senior Software Developer @Ceridian
                <br />
                Completed Master of Computer Applications
                <br />
                Javascript, Java, React, React Native, Android Certification's
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-info mobile-only">
        <div class="descriptions row">
          <h1>Ravi Kiran J</h1>
          <span>
            Senior Software Developer @Ceridian
            <br />
            Completed Master of Computer Applications
            <br />
            Javascript, Java, React, React Native, Android Certification's
          </span>
        </div>
      </div>
    </header>
  );
}
